const getDefaultState = () => {
  return {
    query: null,
    results: null,
    isLoading: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {

  },
  mutations: {
    loadingStart (state, query) {
      state.isLoading = true
      state.query = query
    },
    loadingDone (state, results) {
      state.isLoading = false
      state.results = results
    }
  },
  actions: {
    // Получить список тестов в тер. области
    async search({rootGetters, commit}, query) {
      commit('loadingStart', query)
      if (query.length < 3) return commit('loadingDone', null)
      try {
        const data = await rootGetters.api.search({args: {query: query}})
        commit('loadingDone', data)
      } catch (error) {
        throw error
      }
    }
  }
}