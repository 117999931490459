const getEmailStatus = async function({rootGetters, commit}) {
  try {
    const data = await rootGetters.api.getEmailStatus()
    commit('setEmailStatus', data.emailStatus)
  } catch (error) {
    throw error
  }
}

const confirmEmail = async function({rootGetters, commit}, token) {
  try {
    await rootGetters.api.confirmEmail({args: {token: token}})
    commit('setEmailStatus', 'confirmed')
  } catch (error) {
    throw error
  }
}

const sendEmailConfirm = async function({rootGetters, commit}) {
  try {
    await rootGetters.api.sendEmailConfirm()
    commit('setEmailStatus', 'pending')
  } catch (error) {
    throw error
  }
}

// Запрос на изменения пароля в ЛК
const changePassword = async function({rootGetters}, data) {
  try {
    await rootGetters.api.changePassword({data: data})
  } catch (error) {
    throw error
  }
}

// Запрос на восстановление пароля
const passwordRecoverySendToken = async function({rootGetters}, email) {
  try {
    await rootGetters.api.passwordRecoverySendToken({args: {email: email}})
  } catch (error) {
    throw error
  }
}

// Проверка токена восстановления пароля
const passwordRecoveryCheckToken = async function({rootGetters}, token) {
  try {
    await rootGetters.api.passwordRecoveryCheckToken({args: {token: token}})
  } catch (error) {
    throw error
  }
}

// Запрос на изменение пароля при восстановлении
const passwordRecoveryChangePassword = async function({rootGetters}, data) {
  try {
    await rootGetters.api.passwordRecoveryChangePassword({data: data})
  } catch (error) {
    throw error
  }
}

export default {
  getEmailStatus,
  confirmEmail,
  sendEmailConfirm,
  changePassword,
  passwordRecoverySendToken,
  passwordRecoveryCheckToken,
  passwordRecoveryChangePassword
}