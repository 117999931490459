const getDefaultState = () => {
  return {
    clinicalData: null,
    currentData: null,
    area: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getFilteredDatas(state, getters, rootState, rootGetters) {
      // Если неактивны фильтры или null - вернуть как есть (всё или null)
      if (!rootGetters.isFiltersActive || !state.clinicalData) return state.clinicalData

      if (rootState.currentNosologyId) {
        return state.clinicalData.filter(clinicalData =>
          clinicalData.nosology_id == rootState.currentNosologyId
        )
      }
    }
  },
  mutations: {
    setDatas(state, datas) {
      state.clinicalData = datas
    },
    setCurrentData(state, clinicalData) {
      state.currentData = clinicalData
    },
    resetCurrentData(state) {
      state.currentData = null
    },
    setArea(state, area) {
      state.area = area
    }
  },
  actions: {
    // Получить клинич. случаи в тер. области
    async getTherapyAreaData({rootGetters, commit}, name) {
      try {
        const data = await rootGetters.api.getTherapyAreaData({args: {therapyAreaName: name}})
        commit('setDatas', data)
        commit('setArea', name)
      } catch (error) {
        throw error
      }
    },

    // Получить клинический случай
    async getClinicalData({rootGetters, commit}, id) {
      try {
        const data = await rootGetters.api.getClinicalData({args: {id: id}})
        commit('setCurrentData', data)
      } catch (error) {
        throw error
      }
    },

    // Очистить текущий случай при выходе
    resetClinicalData({commit}) {
      commit('resetCurrentData')
    }
  }
}