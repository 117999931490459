import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    meta: { title: 'МедПрактика Образование' },
    components: {
      default: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
      navbar: () => import(/* webpackChunkName: "navbar" */ '../components/_partials/NavigationBar.vue')
    } 
  },
  {
    path: '/account',
    name: 'Account',
    meta: { title: 'МедПрактика Образование. Личный кабинет' },
    components: {
      default: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
      navbar: () => import(/* webpackChunkName: "navbar" */ '../components/_partials/NavigationBar.vue')
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    meta: { title: 'МедПрактика Образование. Регистрация' },
    components: {
      default: () => import(/* webpackChunkName: "registration" */ '../views/Registration.vue')
    }
  },
  {
    path: '/search',
    name: 'Search',
    meta: { title: 'МедПрактика Образование. Результаты поиска' },    
    components: {
      default: () => import(/* webpackChunkName: "account" */ '@/views/Search.vue'),
      navbar: () => import(/* webpackChunkName: "navbar" */ '@/components/_partials/NavigationBar.vue')
    }
  },
  {
    path: '/email_confirmation/:token',
    name: 'EmailConfirmation',
    meta: { title: 'МедПрактика Образование. Подтверждение e-mail' },
    components: {
      default: () => import(/* webpackChunkName: "emailconfirmation" */'@/views/EmailConfirmation.vue'),
      navbar: () => import(/* webpackChunkName: "navbar" */ '@/components/_partials/NavigationBar.vue')
    },
    props: { default: true, navbar: false }
  },
  ,
  {
    path: '/passwordRecovery/:token',
    name: 'PasswordRecovery',
    meta: { title: 'МедПрактика Образование. Восстановление пароля' },
    components: {
      default: () => import(/* webpackChunkName: "passwordRecovery" */'@/views/PasswordRecovery.vue'),
      navbar: () => import(/* webpackChunkName: "navbar" */ '@/components/_partials/NavigationBar.vue')
    },
    props: { default: true, navbar: false }
  },
  {
    path: '/pulmonology/class',
    meta: { title: 'МедПрактика Образование. Пульмокласс' },
    components: {
      default: () => import(/* webpackChunkName: "pulmoclass" */'@/views/PulmoClass.vue'),
      navbar: () => import(/* webpackChunkName: "navbar" */ '@/components/_partials/NavigationBar.vue')
    },
    children: [
      {
        path: '',
        name: 'PulmonologyClass',
        meta: { title: 'МедПрактика Образование. Пульмокласс'},
        components: {
          content: () => import(/* webpackChunkName: "classcontent" */'@/views/content/ClassContent.vue')
        }
      },
      {
        path: 'videos/:video_id',
        name: 'Video',
        meta: { title: 'МедПрактика Образование. Видео' },
        components: {
          content: () => import(/* webpackChunkName: "videopage" */'@/views/content/ClassVideoPage.vue')
        }
      }
    ]
  },
  {
    path: '/oncology/class',
    meta: { title: 'МедПрактика Образование. Онкокласс' },
    components: {
      default: () => import(/* webpackChunkName: "oncoclass" */'@/views/OncoClass.vue'),
      navbar: () => import(/* webpackChunkName: "navbar" */ '@/components/_partials/NavigationBar.vue')
    },
    children: [
      {
        path: '',
        name: 'OncologyClass',
        meta: { title: 'МедПрактика Образование. Онкокласс'},
        components: {
          content: () => import(/* webpackChunkName: "classcontent" */'@/views/content/ClassContent.vue')
        }
      },
      {
        path: 'videos/:video_id',
        name: 'Video',
        meta: { title: 'МедПрактика Образование. Видео' },
        components: {
          content: () => import(/* webpackChunkName: "videopage" */'@/views/content/ClassVideoPage.vue')
        }
      }
    ]
  },
  {
    path: '/:therapyarea_url',
    components: {
      default: () => import(/* webpackChunkName: "therapyarea" */'@/views/TherapyArea.vue'),
      navbar: () => import(/* webpackChunkName: "navbar" */'@/components/_partials/NavigationBar.vue'),
    },
    children: [
      {
        path: '', redirect: 'videos'
      },
      {
        path: 'videos',
        name: 'Videos',
        meta: { title: 'МедПрактика Образование. Видео-библиотека' },
        components: {
          content: () => import(/* webpackChunkName: "videos" */'@/views/content/Videos.vue')
        }
      },
      {
        path: 'videos/:video_id',
        name: 'Video',
        meta: { title: 'МедПрактика Образование. Видео' },
        components: {
          content: () => import(/* webpackChunkName: "videopage" */'@/views/content/VideoPage.vue')
        }
      },
      {
        path: 'publications',
        name: 'Publications',
        meta: { title: 'МедПрактика Образование. Публикации' },
        components: {
          content: () => import(/* webpackChunkName: "publications" */'@/views/content/Publications.vue')
        }
      },
      {
        path: 'publications/:publication_id',
        name: 'Publication',
        components: {
          content: () => import(/* webpackChunkName: "publicationpage" */'@/views/content/PublicationPage.vue')
        }
      },
      {
        path: 'tests',
        name: 'Tests',
        meta: { title: 'МедПрактика Образование. Тесты' },
        components: {
          content: () => import(/* webpackChunkName: "tests" */'@/views/content/Tests.vue')
        }
      },
      {
        path: 'tests/:test_id',
        name: 'Test',
        meta: { title: 'МедПрактика Образование. Прохождение теста' },
        components: {
          content: () => import(/* webpackChunkName: "testpage" */'@/views/content/TestPage.vue')
        }
      },
      {
        path: 'clinical-cases',
        name: 'ClinicalCases',
        meta: { title: 'МедПрактика Образование. Клинический случай' },
        components: {
          content: () => import(/* webpackChunkName: "clinicalcases" */'@/views/content/ClinicalCases.vue')
        }
      },
      {
        path: 'clinical-cases/:id',
        name: 'ClinicalCase',
        meta: { title: 'МедПрактика Образование. Клинический случай' },
        components: {
          content: () => import(/* webpackChunkName: "clinicalcase" */'@/views/content/ClinicalCasePage.vue')
        }
      },
      {
        path: 'clinical-data',
        name: 'ClinicalDatas',
        meta: { title: 'МедПрактика Образование. Клинические данные' },
        components: {
          content: () => import(/* webpackChunkName: "clinicaldatas" */'@/views/content/ClinicalData.vue')
        }
      },
      {
        path: 'clinical-data/:id',
        name: 'ClinicalData',
        meta: { title: 'МедПрактика Образование. Клинические данные' },
        components: {
          content: () => import(/* webpackChunkName: "clinicaldata" */'@/views/content/ClinicalDataPage.vue')
        }
      },
      {
        path: 'redcollege',
        name: 'Redcollege',
        meta: { title: 'МедПрактика Образование. Научная редакция' },
        components: {
          content: () => import(/* webpackChunkName: "experts" */'@/views/content/Redcollege.vue')
        },
        beforeEnter: async (to, from, next) => {
          const store = await import('../store')
          if (!store.default.getters['auth/isUserActive'])
            next({ name: 'Videos' })
          else
            next()
        }
      },
      {
        path: 'experts',
        name: 'Experts',
        meta: { title: 'МедПрактика Образование. Эксперты' },
        components: {
          content: () => import(/* webpackChunkName: "experts" */'@/views/content/Experts.vue')
        },
        beforeEnter: async (to, from, next) => {
          const store = await import('../store')
          if (!store.default.getters['auth/isUserActive'])
            next({ name: 'Videos' })
          else
            next()            
        }
      },
      {
        path: 'partners',
        name: 'Partners',
        meta: { title: 'МедПрактика Образование. Партнеры' },
        components: {
          content: () => import(/* webpackChunkName: "partners" */'@/views/content/Partners.vue')
        }
      },
      {
        path: 'contacts',
        name: 'Contacts',
        meta: { title: 'МедПрактика Образование. Контакты' },
        components: {
          content: () => import(/* webpackChunkName: "contacts" */'@/views/content/Contacts.vue')
        }
      },
    ]
  },
  {
    path: '*',
    redirect: { name: 'Not found' }
  },
  {
    path: '/not-found',
    name: 'Not found',
    meta: { title: 'МедПрактика Образование. Страница не найдена' },
    components: {
      default: () => import(/* webpackChunkName: "not_found" */ '../views/404.vue'),
      navbar: () => import(/* webpackChunkName: "navbar" */ '../components/_partials/NavigationBar.vue')
    } 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
