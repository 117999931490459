import actions from './actions'
import mutations from './mutations'

const getDefaultState = () => {
  return {
    emailStatus: 'none'
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
}