export const BACKEND_ENDPOINTS = {

  // Main page endpoints
  sendMessage: {method: 'post', url: '/api/sendMessage'},
  sendQuestion: {method: 'post', url: '/api/sendQuestion'},

  // Registration endpoints
  getSpecialities: {method: 'get', url: '/api/specialities'},
  getPositions: {method: 'get', url: '/api/positions'},
  getWorkplaces: {method: 'get', url: '/api/workplaces'},
  register: {method: 'post', url: '/api/users/register'},
  editUser: {method: 'put', url: '/api/users/edit'},

  // Search endpoints
  search: {method: 'get', url: '/api/search?query={query}'},
  
  // TherapyArea endpoints
  getTherapyArea: {method: 'get', url: '/api/therapyareas/{therapyAreaName}'},
  getTherapyAreaVideos: {method: 'get', url: '/api/therapyareas/{therapyAreaName}/videos'},
  getTherapyAreaTests: {method: 'get', url: '/api/therapyareas/{therapyAreaName}/tests'},
  getTherapyAreaExperts: {method: 'get', url: '/api/therapyareas/{therapyAreaName}/experts'},
  getTherapyAreaCases: {method: 'get', url: '/api/therapyareas/{therapyAreaName}/clinical-cases'},
  getTherapyAreaData: {method: 'get', url: '/api/therapyareas/{therapyAreaName}/clinical-data'},

  getPulmoClass: {method: 'get', url: '/api/therapyareas/pulmonology/class'},
  getOncoClass: {method: 'get', url: '/api/therapyareas/oncology/class'},

  // User endpoints
  login: {method: 'post', url: '/api/users/login'},
  logout: {method: 'get', url: '/api/users/logout'},
  checkSession: {method: 'get', url: '/api/users/session'},
  getFavouriteVideos: {method: 'get', url: '/api/users/videos'},
  getRecommendedVideos: {method: 'get', url: '/api/users/recommended'},

  // User endpoints (new User store)
  confirmEmail: {method: 'get', url: '/api/users/email_confirm/{token}'},
  sendEmailConfirm: {method: 'get', url: '/api/users/send_email_confirm'},
  getEmailStatus: {method: 'get', url: '/api/users/email_status'},
  changePassword: {method: 'post', url: '/api/users/changePassword'},
  passwordRecoverySendToken: {method: 'get', url: '/api/users/passwordRecovery/sendToken/{email}'},
  passwordRecoveryCheckToken: {method: 'get', url: '/api/users/passwordRecovery/checkToken/{token}'},
  passwordRecoveryChangePassword: {method: 'post', url: '/api/users/passwordRecovery/changePassword'},

  // Videos endpoints
  getVideo: {method: 'get', url: '/api/videos/{video_id}'},
  addToFavourites: {method: 'get', url: '/api/videos/{video_id}/addFavourite'},
  deleteFromFavourites: {method: 'get', url: '/api/videos/{video_id}/deleteFavourite'},
  likeOrUnlike: {method: 'post', url: '/api/videos/{id}/like'},
  sendVideoLink: {method: 'post', url: '/api/sendVideoLink'},

  // Tests endpoints
  getTest: {method: 'get', url: '/api/tests/{test_id}'},

  // ClinicalCase endpoints
  getClinicalCase: {method: 'get', url: '/api/clinical-cases/{id}'},

  // ClinicalData endpoints
  getClinicalData: {method: 'get', url: '/api/clinical-data/{id}'},

  // Comments endpoints
  sendComment: {method: 'post', url: '/api/comments/new'},
  getComments: {method: 'get', url: '/api/comments/get'},

  // Statistics endpoints
  getVideosStats: {method: 'get', url: '/api/stats/videos'},
  getTestsStats: {method: 'get', url: '/api/stats/tests'},

  postVideoView: {method: 'post', url: '/api/stats/video'},
  patchVideoView: {method: 'patch', url: '/api/stats/video'},
  postTestStatistic: {method: 'post', url: '/api/stats/test'},

  // News endpoint
  getNews: {method: 'get', url: '/api/news'}
}