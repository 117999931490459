<template>
  <div id="app">

    <!-- Фоновая картинка -->
    <div class="h-full w-full fixed bg-no-repeat bg-center" :style='mainBg'></div>

    <!-- Окна Login/Register -->
    <xmodal :params="loginModalOptions" />

    <!-- Navbar -->
    <!-- TODO: убрать из роутера? -->
    <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
      <router-view name="navbar" />
    </fade-transition>

    <!-- Default route с анимацией переходов -->
    <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
      <router-view />
    </fade-transition>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions'

export default {
  components: {
    FadeTransition
  },
  data: function () {
    return {
      mainBg: {
        backgroundImage: `url(${require('./assets/img/bg.png')})`,
        backgroundSize: `contain`,
        zIndex: `-10`
      },
      loginModalOptions: {
        component: require("@/components/LoginModal.vue").default,
        backgroundColor: '#FFFFFF',
        opacity: '0.7'
      }
    }
  },
  created: function() {
    this.$store.dispatch('auth/checkSession')
      .catch((error) => {
        
      })
  },
  mounted(){
    if (process.env.NODE_ENV == 'development' || process.env.VUE_APP_PATH == '/6193/') 
      this.$ga.disable()
    else
      this.$ga.enable()
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

html {
  background-color: #edf2f7;
}

button:active, button:focus, input:active, input:focus {
  outline: none !important;
}
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0 !important;
}
</style>
