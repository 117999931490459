import actions from './actions.js'

const getDefaultState = () => {
  return {
    videos: null,
    currentVideo: null,
    timeSpentSent: null,
    recommended: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getFilteredVideos(state, getters, rootState, rootGetters) {
      if (!rootGetters.isFiltersActive || !state.videos) return state.videos

      let result = state.videos

      if (rootState.currentThemeId) {
        result = state.videos.filter(video => video.theme_id.includes(rootState.currentThemeId))
      } else if (rootState.currentNosologyId) {
        let nosology = rootState.nosologies.find(value => value.id == rootState.currentNosologyId)
        let themes = nosology.themes.map(value => { return value.id })

        result = state.videos.filter(video => video.theme_id.some(function(element) {
          return themes.includes(element)
        }))
      }

      if (!(rootState.showViewed === null)) {
        result = result.filter(video => video.isViewed == rootState.showViewed)
      }

      if (rootState.filterBySpeciality) {
        let positionId = rootGetters.getUserPosition.id
        let specialityId = rootGetters.getUserSpeciality.id

        result = result.filter(video =>
          video.positions.some(position => position.id == positionId) ||
          video.specialities.some(speciality => speciality.id == specialityId)
        )
      }

      return result;
    },
    getUserFavouriteVideos(state) {
      if (!state.videos) return null
      return state.videos.filter(video => video.isFavourite)
    }
  },
  mutations: {
    setVideos(state, videos) {
      state.videos = videos
    },
    resetVideos(state) {
      state.videos = null
    },
    setVideo(state, video) {
      state.currentVideo = video
    },
    resetVideo(state) {
      state.currentVideo = null
    },
    setTimeSpentSent(state, timeSpent) {
      state.timeSpentSent = timeSpent
    },

    // Добавление видео в избранное в state после успешного добавления на сервере
    addToFavourites(state, id) {

      // Если добавление из списка
      if (state.videos != null) {
        let key = state.videos.findIndex((video) => {
          return video.id == id
        })

        state.videos[key].isFavourite = true
      }

      // Если добавление из видео
      if (state.currentVideo != null) {
        state.currentVideo.isFavourite = true
      }
    },

    // Удаление видео из избранного в state после успешного удаления на сервере
    deleteFromFavourites(state, id) {

      // Удаление из списка
      if (state.videos != null) {
        let key = state.videos.findIndex((video) => {
          return video.id == id
        })

        state.videos[key].isFavourite = false
      }

      // Удаление из видео
      if (state.currentVideo != null) {
        state.currentVideo.isFavourite = false
      }
    },
    setFavouriteVideos(state, videos) {
      state.videos = videos
    },
    setRecommendedVideos(state, videos) {
      state.recommended = videos
    },
    setLike(state) {
      state.currentVideo.like = !state.currentVideo.like
      if (state.currentVideo.like) { 
        state.currentVideo.likes += 1
      } else {
        state.currentVideo.likes -= 1
      }
    }
  },
  actions: {
    ...actions,
    // On Videos.vue and UserFavourites beforeDestroy
    resetVideos({commit}) {
      commit('resetVideos')
    },
    // Добавить/удалить из избранного, получить список избранного
    async addToFavourites({rootGetters, commit}, id) {
      try {
        await rootGetters.api.addToFavourites({args: {video_id: id}})
        commit('addToFavourites', id)
      } catch (error) {
        throw error
      }
    },
    async deleteFromFavourites({rootGetters, commit}, id) {
      try {
        await rootGetters.api.deleteFromFavourites({args: {video_id: id}})
        commit('deleteFromFavourites', id)
      } catch (error) {
        throw error
      }
    },

    // Account page
    async getFavouriteVideos({rootGetters, commit}) {
      try {
        const data = await rootGetters.api.getFavouriteVideos()
        commit('setFavouriteVideos', data)
      } catch (error) {
        throw error
      }
    },

    // Account page
    async getRecommendedVideos({rootGetters, commit}) {
      try {
        const data = await rootGetters.api.getRecommendedVideos()
        commit('setRecommendedVideos', data)
      } catch (error) {
        throw error
      }
    },

    // On VideoPage component exit
    resetVideo({commit}) {
      commit('resetVideo')
    },

    // Account UserStats
    async getVideosStats({rootGetters, commit}) {
      try {
        return await rootGetters.api.getVideosStats()
      } catch (error) {
        throw error
      }
    }
  }
}