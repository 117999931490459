const getDefaultState = () => {
  return {
    news: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    setNews(state, news) {
      state.news = news
    }
  },
  actions: {
    async getNews({rootGetters, commit}) {
      try {
        const data = await rootGetters.api.getNews()
        commit('setNews', data)
      } catch (error) {
        throw error
      }
    }
  }
}