const getDefaultState = () => {
  return {
    tests: null,
    currentTest: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getFilteredTests(state, getters, rootState, rootGetters) {
      // Если неактивны фильтры или null - вернуть как есть (всё или null)
      if (!rootGetters.isFiltersActive || !state.tests) return state.tests

      let result = state.tests

      // Если в теме фильтруем по теме
      if (rootState.currentThemeId) {
        result = state.tests.filter(test => test.theme_id == rootState.currentThemeId)
      } else if (rootState.currentNosologyId) {
        let nosology = rootState.nosologies.find(nosology => nosology.id == rootState.currentNosologyId)
        let themes = nosology.themes.map(theme => { return theme.id })

        result = state.tests.filter(test => themes.includes(test.theme_id))
      }

      if (!(rootState.showViewed === null)) {
        return result.filter(test => (test.pointsDone != undefined) == rootState.showViewed)
      }

      return result
    }
  },
  mutations: {
    setTests(state, tests) {
      state.tests = tests
    },
    setTest(state, test) {
      state.currentTest = test
    },
    resetTest(state) {
      state.currentTest = null
    }
  },
  actions: {
    // Получить список тестов в тер. области
    async getTherapyAreaTests({rootGetters, commit}, name) {
      try {
        const data = await rootGetters.api.getTherapyAreaTests({args: {therapyAreaName: name}})
        commit('setTests', data)
      } catch (error) {
        throw error
      }
    },

    // Получить данные теста
    async getTest({rootGetters, commit}, id) {
      try {
        const data = await rootGetters.api.getTest({args: {test_id: id}})
        commit('setTest', data)
        return data.theme_id
      } catch (error) {
        throw error
      }
    },

    // Отправка статистики в конце выполнения теста
    async sendStats({rootGetters}, stats) {
      try {
        await rootGetters.api.postTestStatistic({data: stats})
      } catch (error) {
        throw error
      }
    },

    // Account UserStats
    async getTestsStats({rootGetters, commit}) {
      try {
        return await rootGetters.api.getTestsStats()
      } catch (error) {
        throw error
      }
    },

    // Очистить текущей тест при выходе
    resetTest({commit}) {
      commit('resetTest')
    }
  }
}