import Vue from 'vue'
import Vuex from 'vuex'

// Vuex Modules
import auth from './auth'
import videos from './videos/'
import tests from './tests'
import experts from './experts'
import cases from './cases'
import clinicalData from './clinicalData'
import search from './search'
import comments from './comments'
import news from './news'

import user from './user/'

import api from './apiClient'

import createMutationsSharer from "vuex-shared-mutations"

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    therapyArea: null,
    nosologies: [],
    currentNosologyId: null,
    currentNosologyTitle: null,
    currentThemeId: null,
    showViewed: null,
    areaURL: null,
    filterBySpeciality: false
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  getters: {
    api() {
      return api
    },
    isFiltersActive(state) {
      if (state.currentNosologyId ||
          state.currentThemeId ||
          state.showViewed !== null ||
          state.filterBySpeciality)
        return true
      return false
    },
    isUserHaveAccess(state, getters) {
      return getters['auth/isUserActive']
    },
    getUserPosition(state, getters) {
      return getters['auth/getUserPosition']
    },
    getUserSpeciality(state, getters) {
      return getters['auth/getUserSpeciality']
    },

    // Название текущей темы
    getCurrentThemeName(state) {
      if (state.currentThemeId) {
        let themeName = null

        state.nosologies.find(function(nosology) {
          return nosology.themes.find(function(theme) {
            if (theme.id == state.currentThemeId) {
              themeName = theme.name
              return true
            }
            return false
          })
        })

        return themeName
      }
      return null
    },

    // Название текущей нозологии
    getCurrentNosologyName(state) {
      if (state.currentNosologyTitle) return state.currentNosologyTitle
    },

    // Название текущего контента
    getCurrentContentName(state) {
      if (state.route.name == 'Video' && state.videos.currentVideo)
        return state.videos.currentVideo.name
      if (state.route.name == 'Test' && state.tests.currentTest)
        return state.tests.currentTest.name
      if (state.route.name == 'ClinicalCase' && state.cases.currentCase)
        return state.cases.currentCase.title
      if (state.route.name == 'ClinicalData' && state.clinicalData.currentData)
        return state.clinicalData.currentData.title
      return null
    },

    // Для регулировки действий navbar и breadcrumbs - является ли тек. страница списком
    isItListRoute(state) {
      const listRoutes = ['Videos', 'Publications', 'Tests']
      if (listRoutes.includes(state.route.name)) return true
      return false
    },
    // Для возврата на список со страницы с контентом
    getListRoute(state, getters) {
      if (getters.isItListRoute) return null
      return state.route.path.match(/\/.*\/.*\//)[0]
    }
  },
  mutations: {
    setThemeId(state, id) {
      state.currentThemeId = id
      const nosology = state.nosologies.find(function(element) {
        if (element.themes.find(function(el) {
          return el.id == id ? true : false
        })) return true
        return false
      })
      state.currentNosologyId = nosology.id
      state.currentNosologyTitle = nosology.name
    },
    setNosology(state, nosology) {
      state.currentNosologyId = nosology.id
      state.currentNosologyTitle = nosology.title
      state.currentThemeId = null
    },
    setTherapyArea(state, data) {
      state.therapyArea = data.therapyArea
      state.nosologies = data.nosologies
      state.areaURL = data.therapyArea.url_name
    },
    resetAll(state) {
      const areaURL = state.areaURL
      Object.assign(state, getDefaultState())
      state.areaURL = areaURL
    },

    // Breadcrumb clicks
    resetFilters(state) {
      state.currentNosologyId = null
      state.currentNosologyTitle = null
      state.currentThemeId = null
    },
    resetTheme(state) {      
      state.currentThemeId = null
    },

    // Video list filters (breadcrumbs)
    resetShowViewed(state) {
      state.showViewed = null
    },
    showViewed(state) {
      state.showViewed = true
    },
    showNotViewed(state) {
      state.showViewed = false
    },
    toogleSpecialityFilter(state) {
      state.filterBySpeciality = !state.filterBySpeciality
    }
  },
  actions: {
    // Получить структуру тер. области по имени
    async getTherapyArea({getters, commit}, name) {
      try {
        const data = await getters.api.getTherapyArea({ args: { therapyAreaName: name } })
        commit('setTherapyArea', data)
      } catch (error) {
        throw error
      }
    },

    async getPulmoClass({getters, commit}) {
      try {
        const data = await getters.api.getPulmoClass()
        commit('setTherapyArea', { therapyArea: { id: 11, url_name: 'pulmonology', name: 'Пульмокласс'}, nosologies: []})
        return data
      } catch (error) {
        throw error
      }
    },

    async getOncoClass({getters, commit}) {
      try {
        const data = await getters.api.getOncoClass()
        commit('setTherapyArea', { therapyArea: { id: 12, url_name: 'oncology', name: 'Онкокласс'}, nosologies: []})
        return data
      } catch (error) {
        throw error
      }
    },

    // On therapyArea component exit
    resetTherapyArea({commit}) {
      commit('resetAll')
    },

    // Navigation clicks:
    setCurrentTheme({commit}, id) {
      commit('setThemeId', id)
    },
    setCurrentNosology({commit}, nosology) {
      commit('setNosology', nosology)
    },

    // Breadcrumb clicks:
    setToIndex({commit}) {
      commit('resetFilters')
    },
    resetTheme({commit}) {
      commit('resetTheme')
    },

    // Stickers clicks:
    setToAll({commit}) {
      commit('resetShowViewed')
    },
    setToViewed({commit}) {
      commit('showViewed')
    },
    setToNotViewed({commit}) {
      commit('showNotViewed')
    },
    specialityFilterClick({commit}) {
      commit('toogleSpecialityFilter')
    },

    // Дополнить breadcrumbs при загрузке контента, если не хватает нозологии/темы
    setContentBreadcrumbs({state, commit}, id_array) {
      if (Array.isArray(id_array)) {
        if (!state.currentNosologyId) {
          commit('setThemeId', id_array[0])
        } else if (!state.currentThemeId) {
          const theme = state.nosologies.find((nosology) => nosology.id == state.currentNosologyId)
            .themes.find((theme) => id_array.includes(theme.id))
          commit('setThemeId', theme.id)
        }
      } else {
        commit('setThemeId', id_array)
      }
    },

    // Main page
    async sendMessage({commit}, data) {
      try {
        await api.sendMessage({ data: data })
      } catch (error) {
        throw error
      }
    },

    // Content page
    async sendQuestion({commit}, data) {
      try {
        await api.sendQuestion({ data: data })
      } catch (error) {
        throw error
      }
    }
  },
  modules: {
    auth: auth,
    videos: videos,
    tests: tests,
    experts: experts,
    cases: cases,
    clinicalData: clinicalData,
    search: search,
    comments: comments,
    user: user,
    news: news
  },
  plugins: [createMutationsSharer({ predicate: ['user/setEmailStatus'] })]
})
