import Vue from 'vue'
import App from './App.vue'
import { sync } from 'vuex-router-sync'
import store from './store'
import router from './router'
import VueAnalytics from 'vue-analytics'

sync(store, router)

// v-scroll-to directive and $scrollTo inside components
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
  duration: 900,
  easing: "ease",
  // offset: 0
})

// xmodal-vue plugin for showing custom components as modal
import xmodal from 'xmodal-vue'
Vue.use(xmodal)
Vue.use(VueAnalytics, { id: 'UA-171543542-1', router })

// Font Awesome Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeart, faCheck, faTrophy, faHome, faTimes, faUser, faStar, faThumbsUp, faPlus, faMinus, faCaretRight, faSortDown, faLock, faEnvelopeOpenText, faSortUp, faChevronLeft, faChevronRight, faSearch} from '@fortawesome/free-solid-svg-icons'
import { faYoutubeSquare, faVk, faInstagram, faFacebook, faOdnoklassniki } from '@fortawesome/free-brands-svg-icons'
import { faCheckCircle, faTimesCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faHeart, faCheck, faTrophy, faHome,
  faCheckCircle, faTimesCircle, faQuestionCircle,// Подтверждение e-mail в личном кабинете
  faTimes, faUser, faStar, faThumbsUp, faPlus, faMinus,
  faCaretRight, faSortDown, faLock, faEnvelopeOpenText, faSortUp, faChevronLeft,
  faChevronRight, faSearch, faYoutubeSquare, faVk, faInstagram, faFacebook, faOdnoklassniki
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Tailwind compiled .css
import './assets/css/tailwind.css'

// Disable alert
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')