const getDefaultState = () => {
  return {
    comments: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    // Устанавливает полученные комментарии
    setComments(state, comments) {
      state.comments = comments
    },
    // Добавляет только что отправленный комментарий
    addUserComment(state, comment) {
      state.comments.unshift(comment)
    }
  },
  actions: {
    // Send comment
    async sendComment({rootGetters, commit}, data) {
      try {
        const comment = await rootGetters.api.sendComment({ data: data })
        commit('addUserComment', comment)
      } catch (error) {
        throw error
      }
    },

    // Get comments
    async getComments({rootGetters, commit}, data) {
      try {
        const comments = await rootGetters.api.getComments({ params: data })
        commit('setComments', comments)
      } catch (error) {
        throw error
      }
    }
  }
}