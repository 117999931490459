import axios from 'axios'
import { BACKEND_ENDPOINTS } from './apiEndpoints'

const apiUrl = window.location.protocol + '//' + window.location.hostname

class ApiClientClass {
  constructor(options = {}) {
    this.defaultHeaders = options.headers || {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    this.client = options.client ||
      axios.create({
        baseURL: process.env.NODE_ENV === 'production' ? process.env.BASE_URL : apiUrl,
        headers: this.defaultHeaders,
        withCredentials: true
      });
  }

  urlFormat(url, args) {
    Object.keys(args).forEach((arg) => {
      url = url.replace(`{${arg}}`, args[arg])
    })
    return url
  }
}

export default new Proxy(
  new ApiClientClass(), 
  {
    get: function (target, name) {
      if (BACKEND_ENDPOINTS[name] !== undefined) {
        return ({params = {}, data = {}, args = {}} = {}) => {
          return target.client({
            method: BACKEND_ENDPOINTS[name].method,
            url: target.urlFormat(BACKEND_ENDPOINTS[name].url, args),
            data: data,
            params: params
          })
            .then((response) => {
              return response.data
            })
            .catch((error) => {
              throw error
            })
        }
      } else {
        return target[name]
      }
    }
  }
)