// Получение списка видео в тер. области
const getTherapyAreaVideos = async function({rootGetters, commit}, name) {
  try {
    const data = await rootGetters.api.getTherapyAreaVideos({args: {therapyAreaName: name}})
    commit('setVideos', data)
  } catch (error) {
    throw error
  }
}

// Получение видео на странице видео
const getVideo = async function({rootGetters, commit}, id) {
  try {
    const data = await rootGetters.api.getVideo({args: {video_id: id}})
    commit('setVideo', data)
    return data.theme_id
  } catch (error) {
    throw error
  }
}

// Лайк/анлайк видео
const likeOrUnlike = async function({rootGetters, commit}, id) {
  try {
    const data = await rootGetters.api.likeOrUnlike({args: {id: id}})
    commit('setLike')
  } catch (error) {
    throw error
  }
}

// Отсылка записи о просмотре (один раз в начале просмотра)
const sendView = async function({rootGetters}, id) {
  try {
    await rootGetters.api.postVideoView({data: {video_id: id}})
  } catch (error) {
    throw error
  }
}

// Отсылка текущих данных о просматриваемом видео
const sendViewStats = async function({rootGetters, commit}, stats) {
  commit('setTimeSpentSent', stats.timeSpent)
  try {
    await rootGetters.api.patchVideoView({data: stats})
  } catch (error) {
    throw error
  }
}

// Поделиться ссылкой на видео
const sendVideoLink = async function({rootGetters}, data) {
  try {
    await rootGetters.api.sendVideoLink({data: data})
  } catch (error) {
    throw error
  }
}

export default {
  getTherapyAreaVideos,
  getVideo,
  likeOrUnlike,  
  sendView,
  sendViewStats,
  sendVideoLink
}