<template>
  <div class="flex items-center justify-center vld-parent">
    <div class="max-w-md w-full p-10 rounded-lg shadow-md bg-white">
      
      <!-- Logo and title -->
      <img class="mx-auto h-20 w-auto" src="@/assets/img/logo.png" alt="Workflow" />
      <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-primary-500">
        Вход
      </h2>      

      <!-- Messages area -->
      <div class="w-full mt-6">
        <!-- Success message or login fail message -->
        <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
          <p
            v-if="loginStatus"
            class="p-2 w-full rounded-md text-white" 
            :class="[loginStatus == 'done' ? 'bg-green-600 px-16 py-16' : 'bg-red-700']"
          >            
            <span v-if="loginStatus == 'done'" class="block px-3 font-semibold pb-6">
              <font-awesome-icon icon="check" class="text-5xl p-2 mx-auto my-6 text-green-600 bg-white rounded-full" /><br>
              Вы успешно вошли в аккаунт!
            </span>

            <span v-if="loginStatus == 'fail'">
              <font-awesome-icon icon="times" class="mr-1" />
              Неправильные данные!
            </span>
          </p>
        </fade-transition>

        <!-- Validation error messages -->
        <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
          <p v-if="$v.$invalid && showError" class="text-red-700">
            <font-awesome-icon icon="times" />
            {{ errorMsg }}
          </p>
        </fade-transition>
      </div>

      <!-- Loading animation -->
      <loading
        :active="isLoading"
        :is-full-page="false"
        :loader="'bars'"
        :color="'#00afd3'"
        :height="90"
        :width="90"
        :opacity="0.7"
      />

      <!-- Login form -->
      <form class="mt-2" @submit.prevent="" v-if="loginStatus != 'done'">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md">
          <div>
            <input
              aria-label="Email address"
              v-model="$v.user.email.$model"
              class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-primary-500
                    rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Адрес электронной почты"
            />
          </div>
          <div class="mt-2 flex flex-nowrap">
            <input 
              aria-label="Password"
              v-model="$v.user.password.$model"
              type="password"
              class="appearance-none relative block w-3/5 px-3 py-2 border border-gray-300 placeholder-gray-500 text-primary-500
              rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Пароль"
            />
            <button
              @click="recoverClick()"
              class="text-primary-500 text-sm w-2/5 underline hover:text-primary-300 cursor-pointer">
              Забыли пароль?
            </button>
          </div>
        </div>
        <div class="flex mt-4">
          <button
            @click="loginClick()"
            type="submit"
            class="group relative w-1/3 mr-3 justify-center py-2 px-4 text-sm leading-5 font-medium rounded-md
            text-white bg-primary-600 hover:bg-primary-400 active:bg-primary-600 transition duration-150 ease-in-out"
          >
            Вход
          </button>
          <button
            @click="registrationClick()"
            class="group relative w-2/3 justify-center py-2 px-4 text-sm leading-5 font-medium rounded-md text-white
            bg-primary-600 hover:bg-primary-400 active:bg-primary-600 transition duration-150 ease-in-out"
          >
            Регистрация
          </button>
        </div>

  

        <p class="mt-4 text-sm leading-5 text-gray-600 text-left">
          Если Вы еще не зарегистрированы на нашем образовательном портале, Вы можете воспользоваться только ограниченным набором материалов. Для получения полного доступа ко всем обучающим материалам просим Вас <b>бесплатно</b> пройти регистрацию, либо войти в свой аккаунт, если он уже существует.
        </p>

      </form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

import { FadeTransition } from 'vue2-transitions'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [validationMixin],
  name: 'LoginModal',
  components: { Loading, FadeTransition },
  data: function() {
    return {
      user: {
        email: null,
        password: null
      },
      showError: false,
      isLoading: false,
      loginStatus: null
    }
  },
  computed: {
    errorMsg() {
      if (this.$v.$invalid) {
        if (!this.$v.user.email.required) return 'Введите e-mail!'
        if (!this.$v.user.email.email) return 'Неверный e-mail!'
        if (!this.$v.user.password.required) return 'Введите пароль!'
      }
      return null
    }
  },
  validations: {
    user: {
      email: {
        email,
        required
      },
      password: {
        required
      }
    }
  },
  methods: {
    registrationClick() {
      this.$xmodal.close()
      this.$router.push({ name: 'Registration' })
    },
    loginClick() {
      if (this.$v.$invalid) {
        this.showError = true
        return
      }
      this.isLoading = true
      this.$store.dispatch('auth/loginUser', { username: this.user.email, password: this.user.password })
        .then(() => {
          this.loginStatus = 'done'
          setTimeout(() => { this.$xmodal.close() }, 500)
        })
        .catch(() => {
          this.loginStatus = 'fail'
          this.showError = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    recoverClick() {
      this.$xmodal.close()
      this.$xmodal.open({
        component: import("@/components/RecoverPassModal.vue"),
        backgroundColor: '#aaaaaa',
        opacity: '0.5'
      })
    }
  }
}
</script>