const getDefaultState = () => {
  return {
    experts: null,
    area: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setExperts(state, experts) {
      state.experts = experts
    },
    setArea(state, area) {
      state.area = area
    }
  },
  actions: {
    // Получить экспертов в тер. области
    async getTherapyAreaExperts({rootGetters, commit}, name) {
      try {
        const data = await rootGetters.api.getTherapyAreaExperts({args: {therapyAreaName: name}})
        commit('setExperts', data)
        commit('setArea', name)
      } catch (error) {
        throw error
      }
    }
  }
}