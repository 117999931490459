import user from "./user"

const getDefaultState = () => {
  return {
    user: null,
    loginStatus: null,
    registerStatus: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    isUserLoading(state) {
      if (state.loginStatus == 'loading' || state.loginStatus == null) return true
      return false
    },
    isUserRegistering(state) {
      if (state.registerStatus == 'loading') return true
      return false
    },
    isUserActive(state) {
      if (state.user) return state.user.roles.includes('ROLE_ACTIVE')
      return false
    },
    getUserAreasIds(state) {
      return state.user ? state.user.areas.map(value => { return value.id }) : null
    },
    getFirstUserArea(state) {
      if (!state.user) return null
      if (state.user.areas[0] != null) return state.user.areas[0]
      return null
    },
    getUserPosition(state) {
      if (state.user)
        return state.user.position
    },
    getUserSpeciality(state) {
      if (state.user)
        return state.user.speciality
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    login_request(state) {
      state.loginStatus = 'loading'
    },
    login_done(state, user) {
      state.user = user
      state.loginStatus = 'done'
    },
    login_fail(state) {
      state.loginStatus = 'fail'
    },
    register_request(state) {
      state.registerStatus = 'loading'
    },
    register_done(state, user) {
      state.user = user
      state.registerStatus = 'done'
    },
    register_fail(state) {
      state.registerStatus = 'fail'
    },
    register_error(state) {
      state.registerStatus = 'error'
    },
    auth_success(state, user) {
      state.login_status = 'logged'
      state.user = user
    },
    auth_fail(state) {
      state.login_status = 'fail'
    },
    auth_error(state) {
      state.login_status = 'error'
    }
  },
  actions: {
    // Запрос на логин
    async loginUser({commit, rootGetters}, user) {
      commit('login_request')
      try {
        const data = await rootGetters.api.login({data: user})
        commit('login_done', data)
      } catch (error) {
        commit('login_fail')
        throw Error(error)
      }
    },
    // Проверка наличия валидной сессии, получение юзера
    async checkSession({commit, rootGetters}) {
      commit('login_request')
      try {
        const data = await rootGetters.api.checkSession()
        commit('login_done', data)
      } catch (error) {
        commit('login_fail')
        throw Error(error)
      }
    },
    async logout({commit, rootGetters}) {
      try {
        await rootGetters.api.logout()
        commit('resetState')
      } catch (error) {
        throw Error(error)
      }
    },
    // API request to register user
    async register({commit, rootGetters}, user) {
      commit('register_request')
      try {
        const data = await rootGetters.api.register({data: user})
        commit('register_done', data)
      } catch (error) {
        commit('register_error')
        throw error
      }
    },
    async editUser({commit, rootGetters}, user) {
      try {
        const data = await rootGetters.api.editUser({data: user})
        commit('login_done', data)
      } catch (error) {
        throw error
      }
    }
  }
}