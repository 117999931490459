const getDefaultState = () => {
  return {
    cases: null,
    currentCase: null,
    area: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setCases(state, cases) {
      state.cases = cases
    },
    setCase(state, clinicalCase) {
      state.currentCase = clinicalCase
    },
    resetCase(state) {
      state.currentCase = null
    },
    setArea(state, area) {
      state.area = area
    }
  },
  actions: {
    // Получить клинич. случаи в тер. области
    async getTherapyAreaCases({rootGetters, commit}, name) {
      try {
        const data = await rootGetters.api.getTherapyAreaCases({args: {therapyAreaName: name}})
        commit('setCases', data)
        commit('setArea', name)
      } catch (error) {
        throw error
      }
    },

    // Получить клинический случай
    async getClinicalCase({rootGetters, commit}, id) {
      try {
        const data = await rootGetters.api.getClinicalCase({args: {id: id}})
        commit('setCase', data)
      } catch (error) {
        throw error
      }
    },

    // Очистить текущий случай при выходе
    resetClinicalCase({commit}) {
      commit('resetCase')
    }
  }
}